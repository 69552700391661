<template>
  <div :class="{ 'new-detail-wrap-pc': isPc }">
    <div class="new-detail-wrap">
      <div class="title">{{ selctedInfo.title }}</div>
      <div class="time-wrap">
        <span class="time">{{ selctedInfo.time }}</span>
        <span class="number">浏览次数: 251</span>
      </div>
      <div class="text" v-html="selctedInfo.content"></div>
    </div>
    <div class="control">
      <div class="last" @click="toLast" v-show="nowIndex">上一条</div>
      <div class="back" @click="goLIst">返回</div>
      <div
        class="next"
        @click="toNext"
        v-show="nowIndex !== allInfo.length - 1"
      >
        下一条
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object
    },
    isPc: {
      type: Boolean
    }
  },
  computed: {
    selctedInfo() {
      return this.allInfo[this.nowIndex];
    }
  },
  data() {
    return {
      nowInfo: {},
      allInfo: [],
      nowIndex: 0
    };
  },

  methods: {
    goLIst() {
      this.$emit("showList");
    },
    toLast() {
      this.nowIndex = --this.nowIndex;
      this.toTop();
    },
    toNext() {
      this.nowIndex = ++this.nowIndex;
      this.toTop();
    },
    toTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
  created() {
    this.nowInfo = this.info.nowInfo;
    this.allInfo = this.info.allInfo;
    this.nowIndex = this.allInfo.findIndex(e => e.id === this.nowInfo.id);
  }
};
</script>

<style lang="less" scoped>
@import "./newsPc.less";
.new-detail-wrap {
  padding: 43px 50px 43px 47px;
  overflow-y: scroll;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    max-width: 834px;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
    color: #1d1b1b;
  }
  .time-wrap {
    margin: 32px 0;
    font-size: 30px;
    line-height: 26px;
    color: #adadad;
    .number {
      margin-left: 90px;
    }
  }
  .text {
    /deep/p,
    /deep/div {
      text-align: left;
      font-size: 30px;
      line-height: 72px;
      letter-spacing: 1px;
      color: #333333;
    }
  }
}
.control {
  padding: 30px 50px 30px 47px;
  background-color: #fff;
  width: 100%;
  z-index: 100;
  line-height: 72px;
  letter-spacing: 1px;
  color: #999999;
  position: fixed;
  bottom: 0;
  .last {
    float: left;
    font-size: 30px;
  }
  .next {
    float: right;
    font-size: 30px;
  }
  .back {
    position: absolute;
    font-size: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
