<template>
  <div>
    <!-- 列表 -->
    <div v-show="!detailShow">
      <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
        <img src="../../assets/img/news.png" v-if="!isPc" alt="" />
        <img src="../../assets/img-pc/news-pc.png" v-else alt="" />
        <div class="text">
          <span class="chanese">咨询动态</span>
          <span class="english">Information trends</span>
        </div>
      </div>
      <div class="content-wrap">
        <van-tabs
          :class="{ 'van-tabs-pc': isPc }"
          v-model="activeName"
          title-active-color="#d4101e"
          title-inactive-color="#333333"
        >
          <van-tab
            :name="item.path"
            v-for="item in tabInfo"
            :key="item.id"
            :title="item.title"
            ><newsList
              :id="selectedInfo.id"
              :isPc="isPc"
              :newsId="id"
              @toDetails="toDetails"
          /></van-tab>
        </van-tabs>
      </div>
    </div>

    <!-- 详情 -->
    <div v-if="detailShow">
      <!-- <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
        <img src="../../assets/img-pc/news-pc.png" alt="" />
        <div class="text">
          <span class="chanese">咨询动态</span>
          <span class="english">Information trends</span>
        </div>
      </div> -->
      <detail @showList="showList" :info="detailInfo" :isPc="isPc" />
    </div>
  </div>
</template>

<script>
import newsList from "./list";
import detail from "./details";
export default {
  name: "news-Info",
  props: ["isPc"],
  components: {
    newsList,
    detail
  },
  mounted() {
    this.getTabInfo();
  },
  computed: {
    selectedInfo() {
      let info = {};
      this.tabInfo.length &&
        this.tabInfo.forEach(e => {
          if (this.activeName === e.path) {
            info = e;
          }
        });
      return info;
    }
  },
  data() {
    return {
      activeName: "",
      tabInfo: [],
      detailShow: false,
      detailInfo: {},
      id: this.$route.query.id,
      navigationId: this.$route.query.navigationId
    };
  },
  methods: {
    getTabInfo() {
      const path = this.$route.path;
      let menueList = localStorage.getItem("mobileMenueInfo");
      menueList = menueList ? JSON.parse(menueList) : [];
      menueList.length &&
        menueList.forEach(e => {
          if (e.path === path) {
            this.tabInfo = e.children;
            if (this.navigationId) {
              let info = this.tabInfo.find(e => e.id === this.navigationId);
              if (info) {
                this.activeName = info.path;
              }
            }
          }
        });
    },
    toDetails(item, listData) {
      this.detailInfo = {
        nowInfo: item,
        allInfo: listData
      };
      this.detailShow = true;
    },
    showList() {
      if (this.id) {
        this.$router.push(this.$route.path);
        this.id = "";
        this.navigationId = "";
      }
      this.detailShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.van-tab__text.van-tab__text--ellipsis {
  font-weight: 700;
}
</style>
