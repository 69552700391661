<template>
  <div>
    <van-list
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      finished-text="暂时没有更多了"
      @load="onLoad"
      v-if="!isPc"
    >
      <div class="news-wrap">
        <div
          class="news-content"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <div class="new-img">
            <img :src="item.mainPicture" alt="" />
          </div>
          <div class="news-text">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="description">{{ item.remark }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="news-list-pc-wrap" v-else>
      <div class="news-wrap">
        <div
          class="news-content"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <div class="new-img">
            <img :src="item.mainPicture" alt="" />
          </div>
          <div class="news-text">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="description" v-html="item.content">
              {{ item.content }}
            </div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div>
      <div class="pagenation-wrap">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getFeatureContent } from "@/api/index.js";
export default {
  props: ["id", "newsId", "isPc"],
  data() {
    return {
      loading: false,
      finished: false,
      currentPage: 1,
      pageNumber: 1,
      pageSize: 8,
      sort: "time",
      order: "asc",
      listData: [],
      total: 0
      //   previewShow: false,
      //   imageUrls: [],
      //   startPosition: 0,
      //   previewTitle: ""
    };
  },

  methods: {
    async onLoad() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sort: this.sort,
        order: this.order,
        navigationId: this.id
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.total = result.totalElements;
        if (this.isPc) {
          this.pcHandler(data);
        } else {
          this.mobileHandler(result, data);
        }
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
        this.finished = true;
      }
    },
    mobileHandler(result, data) {
      this.listData.push(...data);
      this.loading = false;
      this.pageNumber = this.pageNumber + 1;
      this.newsId && this.hasIdToDetail(this.newsId, this.listData);
      if (this.pageNumber > result.totalPages) this.finished = true;
    },
    pcHandler(data) {
      this.listData = data;
      this.newsId && this.hasIdToDetail(this.newsId, this.listData);
    },
    hasIdToDetail(id, data) {
      let item = data.find(e => e.id === id);
      this.toDetails(item);
    },
    toDetails(item) {
      this.$emit("toDetails", item, this.listData);
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.onLoad();
    }
  },

  created() {
    this.loading = !this.isPc;
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
@import "./newsPc.less";
.news-wrap {
  padding: 35px 57px 0 47px;
  .news-content {
    margin-bottom: 82px;
    display: flex;
    justify-content: space-between;
    .new-img {
      width: 235px;
      img {
        width: 100%;
        height: 235px;
      }
    }
    .news-text {
      width: 761px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #1d1b1b;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .description {
        font-size: 24px;
        max-height: 132px;
        color: #6b6464;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .time {
        font-size: 24px;
        color: #adadad;
      }
    }
  }
}
/deep/.van-list__finished-text {
  font-weight: bold;
  color: #999999;
}
</style>
